div.modal-wrapper-container {
  max-height: calc(100vh - 2 * 72px - 2 * var(--space-16));
  overflow: hidden;
  width: 100%;

  .content {
    overflow: auto;
    max-height: calc(100vh - 3 * 72px - 2 * var(--space-16));
    padding: var(--space-11) var(--space-11) calc(var(--space-18) - var(--space-17)) var(--space-11);
  }
}
