.remove-duplicates {
  .duplicates-choice-info {
    margin-top: 1rem;

    .italic-duplicates {
      font-style: italic;
      font-weight: 500;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin: 30px 0 10px;

    .filter-button {
      margin: 0 10px 0 10px;
    }
  }

  .info-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .bold {
      font-weight: 500;
    }
  }

  .csv-list {
    margin-top: var(--space-05);
    li {
      list-style-type: disc !important;
      color: var(--text-primary);
    }
  }

  .list-color {
    color: var(--text-primary);
  }

  .bold-color {
    color: var(--text-key);
  }

  .duplicates-wrapper {
    display: flex;
    margin: var(--space-11) 0;
    gap: var(--space-11);
    &__info {
      width: 320px;
    }
  }
}
